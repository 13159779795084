var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('cp-table',{ref:"cpTable",attrs:{"get-data-action":"brokerDealerGroups/getBrokerDealerGroups","filters":_vm.filters,"fields":_vm.brokerDealerGroupFields,"search-value":_vm.searchQueryParam},on:{"onSort":function($event){return _vm.$emit(_vm.onSort, $event)}},scopedSlots:_vm._u([{key:"createdAt",fn:function(ref){
var rowData = ref.rowData;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("dateFilter")(rowData.item.createdAt))+" ")])]}},{key:"issuers",fn:function(ref){
var rowData = ref.rowData;
return _vm._l((rowData.item.issuers),function(issuer){return _c('b-badge',{key:issuer.id,staticClass:"align-text-bottom ml-1",attrs:{"variant":"outline-secondary"}},[_vm._v(" "+_vm._s(issuer.name)+" ")])})}},{key:"enabled",fn:function(ref){
var rowData = ref.rowData;
return [_c('b-badge',{attrs:{"variant":rowData.item.enabled ? 'success' : 'danger'}},[_vm._v(" "+_vm._s(_vm._f("activeInactive")(rowData.item.enabled))+" ")])]}},{key:"actions",fn:function(ref){
var rowData = ref.rowData;
return [_c('b-btn',{attrs:{"variant":"default edit-operator-btn btn-xs md-btn-flat","title":_vm.$t('common.button.edit'),"disabled":_vm.operatorData.email === rowData.item.email},on:{"click":function($event){return _vm.$emit('onEdit', rowData.item)}}},[_c('i',{staticClass:"ion ion-md-create",style:({ color: '#04bec4' })}),_vm._v(" "+_vm._s(_vm.$t('common.button.edit'))+" ")]),_c('b-btn',{attrs:{"variant":"default btn-xs md-btn-flat","title":_vm.getActivationStatus(rowData.item.enabled)},on:{"click":function($event){return _vm.$emit('onChangeStatus', rowData.item)}}},[_c('i',{staticClass:"ion ion-md-switch"}),_vm._v(" "+_vm._s(_vm.getActivationStatus(rowData.item.enabled))+" ")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }