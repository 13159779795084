<template>
  <cp-table
    ref="cpTable"
    get-data-action="organizations/getOrganizationsList"
    :filters="filters"
    :fields="organizationFields"
    :search-value="searchQueryParam"
    :data-parser="convertedInitList"
    @onSort="$emit(onSort, $event)"
  >
    <template
      slot="createdAt"
      slot-scope="{ rowData }"
    >
      <span>
        {{ rowData.item.createdAt | dateFilter }}
      </span>
    </template>
    <template
      slot="actions"
      slot-scope="{ rowData }"
    >
      <b-btn
        variant="default edit-operator-btn btn-xs md-btn-flat"
        :title="$t('common.button.edit')"
        :disabled="currentOrganization === rowData.item.id"
        @click="$emit('onEdit', rowData.item)"
      >
        <i
          class="ion ion-md-create"
          :style="{ color: '#04bec4' }"
        />
        {{ $t('common.button.edit') }}
      </b-btn>
    </template>
  </cp-table>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
import CpTable from '~/components/shared/cp-table';
import { organizationFields } from '../fields';


export default {
  name: 'CpOrganizationsTable',
  components: {
    CpTable,
  },
  props: {
    filters: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      organizationFields,
      searchQueryParam: this.$route.query.search || undefined,
    };
  },
  computed: {
    ...mapGetters('currentOperator', ['operatorData']),
    ...mapState('organizations', ['currentOrganization']),
  },
  watch: {
    $route(to) {
      this.searchQueryParam = to.query.search;
    },
  },
  methods: {
    convertedInitList(data) {
      return data.map((item) => {
        const operators = item.operators.map(operator => operator.name).join(', ');
        return {
          ...item,
          operators,
        };
      });
    },
    updateTableData() {
      this.$refs.cpTable.updateTableData();
    },
  },
};
</script>
