<template>
  <cp-table
    ref="cpTable"
    get-data-action="brokerDealerGroups/getBrokerDealerGroups"
    :filters="filters"
    :fields="brokerDealerGroupFields"
    :search-value="searchQueryParam"
    @onSort="$emit(onSort, $event)"
  >
    <template
      slot="createdAt"
      slot-scope="{ rowData }"
    >
      <span>
        {{ rowData.item.createdAt | dateFilter }}
      </span>
    </template>
    <template
      slot="issuers"
      slot-scope="{ rowData }"
    >
      <b-badge
        v-for="issuer of rowData.item.issuers"
        :key="issuer.id"
        class="align-text-bottom ml-1"
        variant="outline-secondary"
      >
        {{ issuer.name }}
      </b-badge>
    </template>
    <template
      slot="enabled"
      slot-scope="{ rowData }"
    >
      <b-badge
        :variant="rowData.item.enabled ? 'success' : 'danger'"
      >
        {{ rowData.item.enabled | activeInactive }}
      </b-badge>
    </template>
    <template
      slot="actions"
      slot-scope="{ rowData }"
    >
      <b-btn
        variant="default edit-operator-btn btn-xs md-btn-flat"
        :title="$t('common.button.edit')"
        :disabled="operatorData.email === rowData.item.email"
        @click="$emit('onEdit', rowData.item)"
      >
        <i
          class="ion ion-md-create"
          :style="{ color: '#04bec4' }"
        />
        {{ $t('common.button.edit') }}
      </b-btn>
      <b-btn
        variant="default btn-xs md-btn-flat"
        :title="getActivationStatus(rowData.item.enabled)"
        @click="$emit('onChangeStatus', rowData.item)"
      >
        <i class="ion ion-md-switch" />
        {{ getActivationStatus(rowData.item.enabled) }}
      </b-btn>
    </template>
  </cp-table>
</template>
<script>
import { mapGetters } from 'vuex';
import CpTable from '~/components/shared/cp-table';
import { brokerDealerGroupFields } from '../fields';

export default {
  name: 'CpBdGroupsTable',
  components: {
    CpTable,
  },
  props: {
    filters: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      brokerDealerGroupFields,
      searchQueryParam: this.$route.query.search || undefined,
    };
  },
  computed: {
    ...mapGetters('currentOperator', ['operatorData']),
  },
  watch: {
    $route(to) {
      this.searchQueryParam = to.query.search;
    },
  },
  methods: {
    getActivationStatus(enabled) {
      return enabled ? this.$t('common.button.deactivate') : this.$t('common.button.activate');
    },
    updateTableData() {
      this.$refs.cpTable.updateTableData();
    },
  },
};
</script>
