<template>
  <div>
    <cp-general-modal
      ref="bdModal"
      :title="title"
      :title-icon="titleIcon"
      :ok-text="okText"
      :default-value="model"
      @onCancel="resetForm"
      @onInput="handleInputs"
      @shown="setInitialState"
      @onOk="handleSubmit"
    >
      <cp-input
        name="name"
        :label="$t('operators.brokerDealerGroupModal.label.name')"
        validate="required"
      />
      <cp-input
        name="email"
        :label="$t('operators.brokerDealerGroupModal.label.email')"
        validate="required|email"
      />
      <cp-multi-select
        :options="authorizationsList"
        name="authorizations"
        :label="$t('operators.brokerDealerGroupModal.label.authorizations')"
      />
      <cp-dual-List-box
        v-model="model.issuersData"
        v-validate.initial="isRequired"
        name="issuersData"
        :selected-instances="selectedInstances"
        :instances="instances"
        :label="boxNames"
        @select="onSelect"
      />
      <div class="settings">
        <div class="w-100 mb-2 font-weight-semibold text-center">
          {{ settingsLabel }}
        </div>
        <div
          v-for="permission in permissionList"
          :key="permission"
          class="mb-2"
        >
          <cp-switch
            v-model="selectedIssuer[permission]"
            :disabled="!selectedIssuer.name"
          />
          <span class="ml-2">{{ $t(`operators.brokerDealerGroupModal.settings.${permission}`) }}</span>
        </div>
      </div>
    </cp-general-modal>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import CpGeneralModal from '~/components/common/modals-components/general-modal';
import {
  CpInput,
  CpDualListBox,
  CpSwitch,
  CpMultiSelect,
} from '~/components/common/standalone-components/inputs';


export default {
  name: 'CpBdGroupModal',
  components: {
    CpDualListBox,
    CpGeneralModal,
    CpInput,
    CpSwitch,
    CpMultiSelect,
  },
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    instances: {
      type: Array,
      default: () => [],
    },
    brokerDealerGroup: {
      type: Object,
      default: () => {},
    },
    editMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      boxNames: {
        left: this.$t('operators.brokerDealerGroupModal.label.instances'),
        right: this.$t('operators.brokerDealerGroupModal.label.selectedInstances'),
      },
      isRequired: 'required',
      selectedInstances: [],
      model: {
        authorizations: [],
      },
      selectedIssuer: {},
      permissionList: ['userCanBeEditedByIssuer', 'canIssueTokens', 'canAccessToIssuerConfiguration', 'canAccessToIssuerSignatures'],
    };
  },
  computed: {
    ...mapGetters('currentOperator', ['operatorData']),
    ...mapGetters('issuersInfo', ['getIssuerId']),
    title() {
      return this.editMode ? this.$t('operators.brokerDealerGroupModal.title.edit') : this.$t('operators.brokerDealerGroupModal.title.add');
    },
    titleIcon() {
      return this.editMode ? 'md-create' : 'ios-add-circle-outline';
    },
    okText() {
      return this.editMode ? this.$t('operators.brokerDealerGroupModal.button.edit') : this.$t('operators.brokerDealerGroupModal.button.add');
    },
    settingsLabel() {
      const issuerName = (this.selectedIssuer && this.selectedIssuer.name)
        || this.$t('operators.brokerDealerGroupModal.settings.noIssuerSelected');
      return this.$t('operators.brokerDealerGroupModal.settings.label', { issuerName });
    },
    authorizationsList() {
      return this.operatorData.authorizations.filter(authorization => authorization === 'transfer-agent' || authorization === 'allow-export');
    },
  },
  watch: {
    brokerDealerGroup(val) {
      this.selectedInstances = [];
      if (val.issuersData) {
        this.selectedInstances = val.issuersData.map(({
          id,
          userCanBeEditedByIssuer,
          canIssueTokens,
          canAccessToIssuerConfiguration,
          canAccessToIssuerSignatures,
        }) => {
          const { text } = this.instances.find(item => item.value.id === id) || {};
          return {
            text,
            value: {
              id,
              userCanBeEditedByIssuer,
              canIssueTokens,
              canAccessToIssuerConfiguration,
              canAccessToIssuerSignatures,
              name: text,
            },
          };
        });
        this.model = { ...val, issuersData: this.selectedInstances };
      }
    },
  },
  methods: {
    setInitialState() {
      this.isRequired = 'required';
    },
    show() {
      this.$refs.bdModal.show();
    },
    hide() {
      this.$refs.bdModal.hide();
      this.isRequired = false;
      this.resetForm();
    },
    handleSubmit(item) {
      const submitData = { ...item };
      submitData.issuersData = this.convertSelectedList(submitData.issuersData);
      this.$emit('submit', { submitData, editMode: this.editMode });
    },
    resetForm() {
      this.selectedIssuer = {};
      this.model = { authorizations: [] };
    },
    handleInputs(item) {
      if (Object.keys(item).length) {
        this.model = { ...item };
      }
    },
    convertSelectedList(list) {
      return list.map(({
        value: {
          id,
          userCanBeEditedByIssuer,
          canIssueTokens,
          canAccessToIssuerConfiguration,
          canAccessToIssuerSignatures,
        },
      }) => ({
        id,
        userCanBeEditedByIssuer,
        canIssueTokens,
        canAccessToIssuerConfiguration,
        canAccessToIssuerSignatures,
      }));
    },
    onSelect(item) {
      this.selectedIssuer = item;
    },
  },
};
</script>
