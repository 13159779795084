<template>
  <div>
    <cp-general-modal
      ref="organizationModal"
      :title="title"
      :title-icon="titleIcon"
      :ok-text="okText"
      :default-value="model"
      @onCancel="resetForm"
      @onInput="handleInputs"
      @shown="setInitialState"
      @onOk="handleSubmit"
    >
      <cp-input
        name="name"
        :label="$t('operators.organizationModal.label.name')"
        validate="required"
        class="p-1"
      />
      <cp-multi-ip-range
        name="ipsRangeList"
        :value="model.ipsRangeList"
        :validate="'ip'"
      />
    </cp-general-modal>
  </div>
</template>
<script>
import CpGeneralModal from '~/components/common/modals-components/general-modal';
import { CpInput } from '~/components/common/standalone-components/inputs';
import CpMultiIpRange from '../../../components/common/standalone-components/inputs/cp-multi-ip-range';


export default {
  name: 'CpOrganizationModal',
  components: {
    CpGeneralModal,
    CpInput,
    // CpIpRange,
    CpMultiIpRange,
  },
  props: {
    organization: {
      type: Object,
      default: () => {},
    },
    editMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isRequired: 'required',
      model: {},
    };
  },
  computed: {
    title() {
      return this.editMode ? this.$t('operators.organizationModal.title.edit') : this.$t('operators.organizationModal.title.add');
    },
    titleIcon() {
      return this.editMode ? 'md-create' : 'ios-add-circle-outline';
    },
    okText() {
      return this.editMode ? this.$t('operators.organizationModal.button.edit') : this.$t('operators.organizationModal.button.add');
    },
  },
  watch: {
    organization(val) {
      const ipsRangeList = val.organizationIpRanges.map(item => ({ from: item.fromIp, to: item.toIp }));
      this.model = { ...val, ipsRangeList };
    },
  },
  methods: {
    setInitialState() {
      this.isRequired = 'required';
    },
    show() {
      this.$refs.organizationModal.show();
    },
    hide() {
      this.$refs.organizationModal.hide();
      this.isRequired = false;
      this.resetForm();
    },
    handleSubmit(values) {
      const mappedIpsRange = (values.ipsRangeList || []).map(item => ({ from: item.from, to: item.to }));
      const errors = mappedIpsRange.filter(ipRange => Boolean(ipRange.error));
      if (errors && errors.length > 0) return;
      const submitData = { ...values, name: values.name, organizationIpRanges: mappedIpsRange };

      this.$emit('submit', { submitData, editMode: this.editMode });
    },
    resetForm() {
      this.model = {};
    },
    handleInputs(item) {
      if (Object.keys(item).length > 0) {
        this.model.ipsRangeList = item.ipsRangeList;
      }
    },
  },
};
</script>
