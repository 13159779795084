export const operatorFields = [
  {
    key: 'name', i18nKey: 'operators.label.table.name', sortable: true, tdClass: 'align-middle',
  },
  {
    key: 'email', i18nKey: 'operators.label.table.email', sortable: true, tdClass: 'align-middle',
  },
  {
    key: 'lastLoginAt', i18nKey: 'operators.label.table.lastLoginAt', sortable: true, tdClass: 'align-middle',
  },
  {
    key: 'lastLoginIp', i18nKey: 'operators.label.table.lastLoginIp', tdClass: 'align-middle',
  },
  {
    key: 'authorizationLevel', i18nKey: 'operators.label.table.authorizationLevel', sortable: true, tdClass: 'align-middle',
  },
  {
    key: 'createdAt', i18nKey: 'operators.label.table.createdAt', sortable: true, tdClass: 'align-middle',
  },
  {
    key: 'organization', i18nKey: 'operators.label.table.organization', tdClass: 'align-middle',
  },
  {
    key: 'enabled', i18nKey: 'operators.label.table.enabled', sortable: true, tdClass: 'align-middle',
  },
  { key: 'actions', label: ' ', tdClass: 'text-nowrap align-middle text-center' },
];

export const brokerDealerGroupFields = [
  {
    key: 'name', i18nKey: 'operators.label.table.name', sortable: true, tdClass: 'align-middle',
  },
  {
    key: 'createdAt', i18nKey: 'operators.label.table.createdAt', sortable: true, tdClass: 'align-middle',
  },
  { key: 'issuers', i18nKey: 'operators.label.table.issuers' },
  {
    key: 'enabled', i18nKey: 'operators.label.table.enabled', sortable: true, tdClass: 'align-middle',
  },
  { key: 'actions', label: ' ', tdClass: 'text-nowrap align-middle text-center' },
];

export const organizationFields = [
  {
    key: 'name', i18nKey: 'operators.label.table.name', sortable: true, tdClass: 'align-middle',
  },
  {
    key: 'operators', i18nKey: 'operators.label.table.operators', tdClass: 'text-nowrap',
  },
  {
    key: 'createdAt', i18nKey: 'operators.label.table.createdAt', sortable: true, tdClass: 'align-middle',
  },
  { key: 'actions', label: ' ', tdClass: 'text-nowrap' },
];

export const createEditOperatorsAcceptedAuthorizationLevels = {
  admin: ['basic', 'admin'],
  superadmin: ['basic', 'admin', 'superadmin'],
  megaadmin: ['basic', 'admin', 'superadmin', 'megaadmin', 'brokerdealer'],
  brokerdealer: ['brokerdealer'],
};
