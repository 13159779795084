<template>
  <cp-filter
    ref="cpFilter"
    @input="updateFilter"
  >
    <div class="form-row align-items-center">
      <div class="col-md mb-4 filterBlock">
        <cp-select
          name="issuer-id"
          :value="issuerId"
          :label="$t('operators.filter.label.issuers')"
          :options="issuersList"
        />
      </div>

      <div class="col-md mb-4 filterBlock">
        <cp-select
          v-if="!isBrokerDealerGroupsView"
          name="operator-authorization"
          :label="$t('operators.filter.label.authorizations')"
          :options="authorizationsList"
        />
      </div>
      <div class="col-md mb-4 filterBlock">
        <cp-select
          v-if="!isBrokerDealerGroupsView"
          name="authorization-level"
          :label="$t('operators.filter.label.authorizationLevel')"
          :options="authorizationsLevelList"
        />
      </div>
    </div>
  </cp-filter>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import CpSelect from '~/components/common/standalone-components/inputs/cp-select';
import CpFilter from '~/components/common/cp-filter';

export default {
  name: 'CpPanelAdministrationFilter',
  components: {
    CpSelect,
    CpFilter,
  },
  props: {
    isBrokerDealerGroupsView: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      issuerId: null,
    };
  },
  computed: {
    ...mapState('issuerList', ['allSystemIssuersList']),
    ...mapState('operators', ['operatorsList']),
    ...mapGetters('currentOperator', ['operatorData', 'authLevel']),
    ...mapState('authorize', ['authorizationsLevels']),
    ...mapGetters('issuersInfo', ['getIssuerId']),
    currentOperator() {
      return this.operatorData;
    },

    issuersList() {
      return [{ text: this.$t('common.label.all'), value: null }]
        .concat(this.allSystemIssuersList.map(({ issuerId, name }) => ({ value: issuerId, text: name })));
    },
    authorizationsLevelList() {
      const authLvl = this.authorizationsLevels[this.currentOperator.authorizationLevel];
      return [
        { text: this.$t('common.label.all'), value: null },
      ].concat((authLvl.length ? authLvl : [])
          .map(authLevel => ({ text: this.$t(`common.category.authorizationLevel.${authLevel}`), value: authLevel })));
    },
    authorizationsList() {
      return [
        { text: this.$t('common.label.all'), value: null },
        ...this.authLevel,
      ];
    },
  },
  created() {
    this.issuerId = this.getIssuerId || this.$route.query.issuer;
    if (this.issuerId) this.updateFilter({ 'issuer-id': this.issuerId });
    this.getAllSystemIssuers().then();
  },
  methods: {
    ...mapActions('issuerList', ['getAllSystemIssuers']),
    updateFilter(filterData) {
      this.$emit('updateFilter', filterData);
      this.$router.replace({ query: { ...this.$route.query, issuer: filterData['issuer-id'] || undefined } });
    },
  },
};
</script>

<style lang="scss">
    .filterBlock {
        margin: 0 2%;
        min-width: 25%;
    }
</style>
