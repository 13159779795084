<template>
  <div>
    <cp-operator-modal
      ref="operatorModal"
      :instances="getIssuersForOperatorModal"
      :selected-instances="selectedList"
      :operator-info="operator"
      :edit-mode="editMode"
      @submit="submitOperatorData"
    />
    <cp-bd-group-modal
      ref="bdGroupModal"
      :instances="getIssuersForBrokerDealerGroupModal"
      :selected-instances="selectedList"
      :broker-dealer-group="brokerDealerGroup"
      :edit-mode="editMode"
      @submit="submitBrokerDealerGroupData"
    />
    <cp-organization-modal
      ref="organizationModal"
      :organization="organization"
      :edit-mode="editMode"
      @submit="submitOrganizationData"
    />
    <cp-confirm-modal
      ref="cpConfirmModal"
      :title="confirmModalTitle"
      :item="currentItem"
      @onOk="submitStatus"
    >
      {{ confirmModalText }}
    </cp-confirm-modal>
    <cp-confirm-modal
      ref="cpResetConfirmModal"
      :title="$t('operators.resetConfirmModal.title')"
      :item="currentItem"
      @onOk="callResetAuthentication"
    >
      {{ $t('operators.resetConfirmModal.message') }}
    </cp-confirm-modal>
    <cp-head-table
      :title="$t('operators.head.title')"
      :tooltip-text="$t('operators.head.disabledTooltip')"
      title-icon="ios-build"
      :is-button-disabled="isDisabledAddOperators"
      :button-title="buttonTitle"
      button-icon="ios-add-circle-outline"
      @onCreateElem="toCreateOperatorModal"
    >
      <b-dropdown
        v-if="isMegaadmin"
        variant="primary"
      >
        <template v-slot:button-content>
          <i class="ion ion-ios-add-circle-outline" />
          {{ $t('operators.dropdown.title') }}
        </template>
        <b-dropdown-item @click="toCreateOperatorModal">
          {{ $t('operators.dropdown.operator') }}
        </b-dropdown-item>
        <b-dropdown-item @click="toCreateBrokerDealerGroupModal">
          {{ $t('operators.dropdown.brokerDealerGroup') }}
        </b-dropdown-item>
        <b-dropdown-item @click="toCreateOrganizationModal">
          {{ $t('operators.dropdown.organization') }}
        </b-dropdown-item>
      </b-dropdown>
    </cp-head-table>
    <cp-tabs
      v-model="selectedTab"
      class="my-4"
      :tabs="tabs"
      @input="resetSearch"
    />
    <cp-panel-administration-filter
      v-if="shouldDisplayFilters"
      :is-broker-dealer-groups-view="isBrokerDealerGroupsView"
      @updateFilter="applyFilters"
    />
    <cp-organizations-table
      v-if="isOrganizationsView"
      ref="cpOrganizationsTable"
      @onEdit="toEditOrganizationModal"
      @onReset="callResetConfirmModal"
      @onChangeStatus="callConfirmModal"
    />
    <cp-bd-groups-table
      v-if="isBrokerDealerGroupsView"
      ref="cpBdGroupsTable"
      :filters="filters"
      @onEdit="toEditBrokerDealerGroupModal"
      @onReset="callResetConfirmModal"
      @onChangeStatus="callConfirmModal"
    />
    <cp-operators-table
      v-if="isOperatorsView"
      ref="cpOperatorsTable"
      :filters="filters"
      :disable-edit-operator="!hasAllIssuersList"
      @onEdit="toEditOperatorModal"
      @onHSM="redirectHSMControlPage"
      @onReset="callResetConfirmModal"
      @onChangeStatus="callConfirmModal"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import CpHeadTable from '~/components/common/head';
import CpOperatorModal from './components/cp-operator-modal';
import CpBdGroupModal from './components/cp-bd-group-modal';
import CpBdGroupsTable from './components/cp-bd-groups-table';
import CpOperatorsTable from './components/cp-operators-table';
import CpConfirmModal from '~/components/common/modals-components/confirm-modal';
import CpTabs from '~/components/common/standalone-components/cp-tabs';
import CpPanelAdministrationFilter from './components/cp-filter';
import CpOrganizationModal from './components/cp-organization-modal';
import CpOrganizationsTable from './components/cp-organizations-table';

export default {
  name: 'Operators',
  metaInfo: {
    title: 'Operators',
  },
  layout: 'main',
  components: {
    CpHeadTable,
    CpOperatorModal,
    CpOperatorsTable,
    CpConfirmModal,
    CpBdGroupModal,
    CpTabs,
    CpBdGroupsTable,
    CpPanelAdministrationFilter,
    CpOrganizationModal,
    CpOrganizationsTable,
  },
  data() {
    return {
      issuerId: '',
      selectedList: [],
      operator: {},
      brokerDealerGroup: {},
      organization: {},
      editMode: false,
      currentItem: {},
      confirmModalText: '',
      filters: {},
      disabledAddOperators: false,
      selectedTab: '',
    };
  },
  computed: {
    ...mapState('issuerList', ['allSystemIssuersList']),
    ...mapGetters('currentOperator', ['operatorData']),
    ...mapGetters('operators', ['getEditableOperator']),
    hasAllIssuersList() {
      return Boolean((this.allSystemIssuersList && this.allSystemIssuersList.length));
    },
    getIssuersForOperatorModal() {
      let issuerList = [];
      if (this.hasAllIssuersList) {
        issuerList = (this.allSystemIssuersList.filter(i => i.isDeleted === 0)).map(({ issuerId, name }) => ({
          value: { id: issuerId, name },
          text: name,
        }));
      }
      return issuerList;
    },
    getIssuersForBrokerDealerGroupModal() {
      let issuerList = [];
      if (this.hasAllIssuersList) {
        issuerList = this.allSystemIssuersList.map(({ issuerId, name }) => ({
          value: {
            id: issuerId,
            name,
            userCanBeEditedByIssuer: false,
            canIssueTokens: false,
            canAccessToIssuerConfiguration: false,
            canAccessToIssuerSignatures: false,
          },
          text: name,
        }));
      }
      return issuerList;
    },
    isDisabledAddOperators() {
      return this.operatorData && this.operatorData.authorizationLevel === 'superadmin' && this.allSystemIssuersList && this.allSystemIssuersList.length <= 0;
    },
    buttonTitle() {
      const { authorizationLevel } = this.operatorData;
      return authorizationLevel === 'basic' ? '' : this.$t('operators.button.operators.add');
    },
    isMegaadmin() {
      return this.operatorData && this.operatorData.authorizationLevel === 'megaadmin';
    },
    isBrokerdealer() {
      return this.operatorData && this.operatorData.authorizationLevel === 'brokerdealer';
    },
    tabs() {
      const tabs = [{ value: 'operators', text: this.$t('operators.tabs.operators') }];

      if (this.isMegaadmin) {
       tabs.push({ value: 'brokerDealerGroups', text: this.$t('operators.tabs.brokerDealerGroups') });
       tabs.push({ value: 'organizations', text: this.$t('operators.tabs.organizations') });
      }

      return tabs;
    },
    isOperatorsView() {
      return this.selectedTab === 'operators';
    },
    isBrokerDealerGroupsView() {
      return this.selectedTab === 'brokerDealerGroups';
    },
    isOrganizationsView() {
      return this.selectedTab === 'organizations';
    },
    confirmModalTitle() {
      const type = this.isBrokerDealerGroupsView
        ? this.$t('operators.dropdown.brokerDealerGroup')
        : this.$t('operators.dropdown.operator');
      return this.$t('operators.confirmModal.title', { type });
    },
    shouldDisplayFilters() {
      return !this.isBrokerdealer && !this.isOrganizationsView;
    },
  },
  created() {
    this.getAllSystemIssuers().then();
    if (this.isMegaadmin) {
    this.getOrganizationsList().then();
    }
  },
  methods: {
    ...mapActions({
      deactivateOperator: 'operators/deactivateOperator',
      activateOperator: 'operators/activateOperator',
      updateOperator: 'operators/updateOperator',
      addOperator: 'operators/addOperator',
      getOperatorById: 'operators/getOperatorById',
      resetAuthentication: 'operators/resetAuthentication',
      addBrokerDealerGroup: 'brokerDealerGroups/addBrokerDealerGroup',
      deactivateBrokerDealerGroup: 'brokerDealerGroups/deactivateBrokerDealerGroup',
      activateBrokerDealerGroup: 'brokerDealerGroups/activateBrokerDealerGroup',
      updateBrokerDealerGroup: 'brokerDealerGroups/updateBrokerDealerGroup',
      getBrokerDealerGroupById: 'brokerDealerGroups/getBrokerDealerGroupById',
      getAllSystemIssuers: 'issuerList/getAllSystemIssuers',
      getOrganizationById: 'organizations/getOrganizationById',
      addOrganization: 'organizations/addOrganization',
      updateOrganization: 'organizations/updateOrganization',
      getOrganizationsList: 'organizations/getOrganizationsList',
    }),
    toCreateBrokerDealerGroupModal() {
      this.brokerDealerGroup = {};
      this.editMode = false;
      this.$refs.bdGroupModal.show();
    },
    toEditBrokerDealerGroupModal({ id }) {
      this.getBrokerDealerGroupById(id).then((data) => {
        this.brokerDealerGroup = data;
        this.editMode = true;
        this.$refs.bdGroupModal.show();
      });
    },
    toCreateOrganizationModal() {
      this.organization = {};
      this.editMode = false;
      this.$refs.organizationModal.show();
    },
    toEditOrganizationModal({ id }) {
      this.getOrganizationById(id).then((data) => {
        this.organization = data;
        this.editMode = true;
        this.$refs.organizationModal.show();
      });
    },
    toCreateOperatorModal() {
      this.operator = {};
      this.editMode = false;
      this.$refs.operatorModal.show();
    },
    toEditOperatorModal(item) {
      this.getOperatorById(item.id).then((data) => {
        this.operator = data;
        this.editMode = true;
        this.$refs.operatorModal.show();
      });
    },
    redirectHSMControlPage(item) {
      this.$router.push({ name: 'hsm-details', params: { operatorId: item.id } });
    },
    applyFilters(data) {
      this.filters = data;
    },
    submitOperatorData({ editMode, model }) {
      if (!editMode) {
        this.addOperator(model)
          .then(() => {
            this.updateOperatorsTableData();
            this.$refs.operatorModal.hide();
          });
      } else {
        this.updateOperator({
          operatorId: model.id,
          body: model,
        })
          .then(() => {
            this.updateOperatorsTableData();
            this.$refs.operatorModal.hide();
          });
      }
    },
    submitBrokerDealerGroupData({ submitData, editMode }) {
      if (!editMode) {
        this.addBrokerDealerGroup(submitData).then(() => {
          this.updateBrokerDealerGroupsTableData();
          this.$refs.bdGroupModal.hide();
        });
      } else {
        this.updateBrokerDealerGroup({
          brokerDealerGroupId: submitData.id,
          body: submitData,
        }).then(() => {
          this.updateBrokerDealerGroupsTableData();
          this.$refs.bdGroupModal.hide();
        });
      }
    },
    submitOrganizationData({ submitData, editMode }) {
      if (!editMode) {
        this.addOrganization(submitData).then(() => {
          this.updateOrganizationsTableData();
          this.$refs.organizationModal.hide();
        });
      } else {
        this.updateOrganization({
          organizationId: submitData.id,
          body: submitData,
        }).then(() => {
          this.updateOrganizationsTableData();
          this.$refs.organizationModal.hide();
        });
      }
    },
    submitStatus(item) {
      if (this.isBrokerDealerGroupsView) {
        this.submitBrokerDealerGroupStatus(item);
      }
      if (this.isOperatorsView) {
        this.submitOperatorStatus(item);
      }
    },
    submitOperatorStatus({ legacyId, enabled }) {
      if (enabled) {
        this.deactivateOperator(legacyId).then(() => {
          this.updateOperatorsTableData();
        });
      } else {
        this.activateOperator(legacyId).then(() => {
          this.updateOperatorsTableData();
        });
      }
    },
    submitBrokerDealerGroupStatus({ id, enabled }) {
      if (enabled) {
        this.deactivateBrokerDealerGroup(id).then(() => {
          this.updateBrokerDealerGroupsTableData();
        });
      } else {
        this.activateBrokerDealerGroup(id).then(() => {
          this.updateBrokerDealerGroupsTableData();
        });
      }
    },
    callResetAuthentication({ legacyId }) {
      this.resetAuthentication(legacyId).then(() => {
        this.updateOperatorsTableData();
      });
    },
    callConfirmModal(item) {
      this.currentItem = item;
      const status = item.enabled
        ? this.$t('operators.confirmModal.status.deactivate')
        : this.$t('operators.confirmModal.status.activate');
      this.confirmModalText = this.$t('operators.confirmModal.message', { status, name: item.name });
      this.$refs.cpConfirmModal.show();
    },
    callResetConfirmModal(item) {
      this.currentItem = item;
      this.$refs.cpResetConfirmModal.show();
    },
    updateOperatorsTableData() {
      if (!this.isBrokerDealerGroupsView) this.$refs.cpOperatorsTable.updateTableData();
    },
    updateBrokerDealerGroupsTableData() {
      if (this.isBrokerDealerGroupsView) this.$refs.cpBdGroupsTable.updateTableData();
    },
    updateOrganizationsTableData() {
      if (this.isOrganizationsView) this.$refs.cpOrganizationsTable.updateTableData();
    },
    resetSearch() {
      this.$router.push({ query: { ...this.$route.query, search: undefined } });
    },
  },
};
</script>
