<template>
  <div>
    <cp-general-modal
      ref="operatorModal"
      :title="title"
      :title-icon="titleIcon"
      :ok-text="title"
      :default-value="model"
      @onCancel="resetForm"
      @onInput="handleInputs"
      @onOk="handleSubmit"
    >
      <cp-input
        name="name"
        :label="$t('operators.operatorModal.label.name')"
        validate="required"
      />
      <cp-input
        name="email"
        :label="$t('operators.operatorModal.label.email')"
        :disabled="editMode"
        validate="required|email"
      />
      <cp-select
        v-if="!isBrokerDealer"
        name="authorizationLevel"
        :label="$t('operators.operatorModal.label.authorizationLevel')"
        :options="authorizationLevelOptions"
      />
      <cp-select
        v-if="shouldDisplayOrganizations"
        name="organizationId"
        :value="model.organizationId"
        :label="$t('operators.operatorModal.label.organization')"
        :options="getOrganizationOptions"
      />
      <cp-select
        v-if="isMegaAdmin && model.authorizationLevel==='brokerdealer' && brokerDealerGroupsDropdown.length"
        name="brokerDealerGroupId"
        :label="$t('operators.operatorModal.label.brokerDealerGroup')"
        :options="brokerDealerGroupsDropdown"
        :validate="model.authorizationLevel==='brokerdealer' ? 'required' : ''"
      />
      <span v-if="isMegaAdmin && model.authorizationLevel==='brokerdealer' && !brokerDealerGroupsDropdown.length">
        {{ $t('operators.operatorModal.warning.emptyBrokerDealerGroupList') }}
      </span>
      <cp-dual-list-box
        v-show="!['megaadmin', 'brokerdealer'].includes(model.authorizationLevel)"
        v-model="model.issuerIds"
        v-validate.initial="issuersValidation"
        name="issuerIds"
        :selected-instances="selectedInstances"
        :instances="instances"
        :label="boxNames"
      />
      <cp-multi-select
        v-if="authorizationsList.length && !isBasic"
        :value="multiSelectDefault"
        :options="authorizationsList"
        name="authorizations"
        :label="$t('operators.operatorModal.label.authorizations')"
      />
      <div class="col text-muted">
        <label class="form-label">
          {{ $t('operators.operatorModal.label.createdBy') }}
        </label>  <br>
        <span>
          {{ getCreatedBy }}
        </span>
      </div>
    </cp-general-modal>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import CpGeneralModal from '~/components/common/modals-components/general-modal';
import {
  CpInput,
  CpSelect,
  CpDualListBox,
  CpMultiSelect,
} from '~/components/common/standalone-components/inputs';
import CpValidationFields from '~/mixins/validation-fields';
import { createEditOperatorsAcceptedAuthorizationLevels } from '../fields';

export default {
  name: 'CpOperatorsModal',
  components: {
    CpDualListBox,
    CpGeneralModal,
    CpInput,
    CpSelect,
    CpMultiSelect,
  },
  mixins: [
    CpValidationFields,
  ],
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    instances: {
      type: Array,
      default: () => [],
    },
    operatorInfo: {
      type: Object,
      default: () => {},
    },
    editMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      model: {
        authorizationLevel: 'basic',
        authorizations: [],
        issuerIds: [],
      },
      boxNames: {
        left: this.$t('operators.operatorModal.label.instances'),
        right: this.$t('operators.operatorModal.label.selectedInstances'),
      },
      selectedInstances: [],
      currentOperator: {
        name: null,
        id: null,
      },
      createdBy: '',
      canCreateMegaadminFlag: process.env.VUE_APP_CAN_CREATE_MEGAADMIN === 'true' ? 1 : 0,
    };
  },
  computed: {
    ...mapGetters('brokerDealerGroups', ['brokerDealerGroupsDropdown']),
    ...mapGetters('currentOperator', ['operatorData']),
    ...mapGetters('issuersInfo', ['getIssuerId']),
    ...mapGetters('organizations', ['organizationsList']),
    issuersValidation() {
      const { authorizationLevel } = this.model;
      if (['megaadmin', 'brokerdealer'].includes(authorizationLevel)) return '';
      if (this.editMode && authorizationLevel === 'superadmin') return '';
      return (authorizationLevel && authorizationLevel !== 'basic') ? 'required' : 'max_list_length:1|required';
    },
    multiSelectDefault() {
      const authorization = [];
      if (this.operatorData
              && this.operatorData.authorizations
              && !this.editMode
              && this.operatorData.authorizations.find(auth => auth === 'securitize-markets')) {
        authorization.push('securitize-markets');
      }
      return authorization;
    },
    isBrokerDealer() {
      return this.operatorData && this.operatorData.authorizationLevel === 'brokerdealer';
    },
    isMegaAdmin() {
      return this.operatorData && this.operatorData.authorizationLevel === 'megaadmin';
    },
    isBasic() {
      return this.model.authorizationLevel === 'basic';
    },
    shouldDisplayOrganizations() {
      return this.isMegaAdmin && this.model.authorizationLevel !== 'brokerdealer';
    },
    getCreatedBy() {
      return this.editMode ? this.operatorInfo.createdBy : this.currentOperator.name;
    },
    title() {
      return this.editMode ? this.$t('operators.operatorModal.title.edit') : this.$t('operators.operatorModal.title.add');
    },
    isAddMode() {
      return !this.editMode;
    },
    titleIcon() {
      return this.editMode ? 'md-create' : 'ios-add-circle-outline';
    },
    authorizationsList() {
      const authList = this.operatorData.authorizations;
      if (!this.isMegaAdmin || this.model.authorizationLevel === 'brokerdealer') return [];
      return authList.filter(val => val !== 'can-create-megaadmin');
    },
    authorizationLevelOptions() {
      const authLevelOptions = createEditOperatorsAcceptedAuthorizationLevels[this.operatorData.authorizationLevel]
          .map(value => ({ text: this.$t(`operators.option.authorizationLevel.${value}`), value }));
      if (!this.canCreateMegaadminFlag) {
        if (this.isMegaAdmin && !this.operatorData.authorizations.includes('can-create-megaadmin')) return authLevelOptions.filter(option => option.value !== 'megaadmin');
      }
      return authLevelOptions;
    },
    getOrganizationOptions() {
      return [{ text: 'None', value: null }].concat((this.organizationsList || []).map(({ name, id }) => ({ text: name, value: id })));
    },
  },
  watch: {
    operatorInfo(val) {
      const currentModalVal = { ...val };
      this.selectedInstances = [];
      if (val.issuerIds) {
        this.selectedInstances = this.instances.filter(item => val.issuerIds.find(i => i === item.value.id));
        if (this.editMode && this.operatorData.authorizationLevel !== 'megaadmin') {
          delete currentModalVal.authorizations;
        }
        this.model = { ...currentModalVal };
      }
    },
  },
  mounted() {
    this.currentOperator.name = this.operatorData.name;

    if (this.isMegaAdmin && !this.brokerDealerGroupsDropdown.length) this.getBrokerDealerGroupListDropdown();
    if (!this.editMode && this.isBrokerDealer) this.model.authorizationLevel = 'brokerdealer';
  },
  methods: {
    ...mapActions('brokerDealerGroups', ['getBrokerDealerGroupListDropdown']),
    show() {
      this.$refs.operatorModal.show();
    },
    hide() {
      this.$refs.operatorModal.hide();
      this.resetForm();
    },
    handleSubmit(item) {
      const submitData = { ...item };
      if (['megaadmin', 'brokerdealer'].includes(submitData.authorizationLevel)) submitData.issuerIds = [];
      if (submitData.issuerIds && submitData.issuerIds.length) submitData.issuerIds = this.convertSelectedList(submitData.issuerIds);
      if (['basic', 'brokerdealer'].includes(submitData.authorizationLevel)) submitData.authorizations = [];
      if (submitData.authorizationLevel !== 'brokerdealer' && submitData.brokerDealerGroupId) delete submitData.brokerDealerGroupId;
      if (this.operatorData.authorizationLevel !== 'megaadmin'
          && this.operatorData.authorizations.find(auth => auth === 'securitize-markets')
          && !this.editMode
          && !submitData.authorizations.find(auth => auth === 'securitize-markets')) {
        submitData.authorizations.push('securitize-markets');
      }
      if (!this.editMode && this.operatorData.organizationId) submitData.organizationId = this.operatorData.organizationId;

      this.$emit('submit', { model: submitData, editMode: this.editMode });
    },
    resetForm() {
      this.model = {};
      this.model.authorizations = [];
      this.selectedInstances = [];
      this.model.authorizationLevel = this.isBrokerDealer ? 'brokerdealer' : 'basic';
      this.model.issuerIds = [];
    },
    convertSelectedList(item) {
      if (typeof item[0] === 'object') {
        return item.map(issuer => issuer.value.id);
      }
      return item;
    },
    handleInputs(item) {
      if (Object.keys(item).length) {
        this.model = { ...this.model, ...item };
      }
    },
  },
};
</script>
