<template>
  <div>
    <div class="row mt-1">
      <div class="ml-3 ip-range-title">
        {{ $t('operators.organizationModal.label.ipRange') }}
      </div>
    </div>
    <div
      v-for="(item, index) in ipsRangeList"
      :key="'ipItem_'+ index"
      class="row p-1"
    >
      <cp-input
        v-model="item.from"
        :validate="validate"
        :error="errors['fromIp_'+index]"
        :name="'fromIp_'+index"
        class="col-md-5"
        :placeholder="$t('operators.organizationModal.label.fromIp')"
        @input="handleInput(item, index,{e:$event})"
      />
      <cp-input
        v-model="item.to"
        :validate="validate"
        :error="errors['toIp_'+index]"
        :name="'toIp_'+index"
        class="col-md-5"
        :placeholder="$t('operators.organizationModal.label.toIp')"
        @input="handleInput(item, index,{e:$event})"
      />
      <div class="col-md-1">
        <b-btn
          v-if="index!==0"
          v-b-tooltip.hover
          variant="default borderless md-btn-flat icon-btn text-muted"
          title="Move to trash"
          :disabled="index===0"
          @click="deleteItem(index)"
        >
          <i class="ion ion-md-trash" />
        </b-btn>
      </div>
      <div class="col-md-1">
        <b-btn
          v-if="ipsRangeList.length<10"
          v-b-tooltip.hover
          variant="default borderless md-btn-flat icon-btn text-muted"
          title="Add item"
          @click="createNewItem()"
        >
          <i class="ion ion-ios-add-circle-outline" />
        </b-btn>
      </div>
      <span
        v-if="item.error"
        class="ml-2 invalid-feedback d-block"
      >
        {{ item.error }}
      </span>
    </div>
  </div>
</template>

<script>
    import CpInput from '~/components/common/standalone-components/inputs/cp-input';

    export default {
        name: 'CpMultiIpRange',
        components: {
            CpInput,
        },
        props: {
            name: {
                type: String,
                default: '',
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            inputClass: {
                type: [String, Object, Array],
                default: '',
            },
            value: {
                type: Array,
                default: () => ([{ from: '', to: '', error: '' }]),
            },
            validate: {
                type: String,
                default: '',
            },
            label: {
                type: String,
                default: '',
            },
        },
        data() {
            return {
                fromVal: '',
                toVal: '',
                controlledValue: this.value,
                controlledError: '',
                error: '',
                ipsRangeList: [{ from: '', to: '', error: '' }],
            };
        },
        computed: {
            errors() {
                return this.validationErrors.items.reduce(
                    (res, error) => {
                        res[error.field] = error.msg;
                        return res;
                    },
                    {},
                );
            },
        },
        watch: { // clear local data
            controlledValue(val) {
              if (val === null) {
                    this.fromVal = '';
                    this.toVal = '';
                    this.controlledError = '';
                    this.ipsRangeList = [{ from: '', to: '', error: '' }];
                }
            },
            value: {
              immediate: true,
              handler(newVal) {
                if (newVal && newVal.length) {
                  this.ipsRangeList = [...newVal];
                }
              },
            },
        },
        methods: {
            handleInput(item, index) {
              if (!this.isValidIpRange(this.ipsRangeList[index].from, this.ipsRangeList[index].to)) {
                this.ipsRangeList[index].error = 'IP\'s must conform a valid range.';
              } else {
                this.ipsRangeList[index].error = null;
              }

              this.$emit('input', [...this.ipsRangeList]);
            },
            isValidIpRange(fromIp, toIp) {
              if ((fromIp && !toIp) || (!fromIp && toIp)) {
                return false;
              }

              const fromIpNumber = this.ipToNumber(fromIp);
              const toIpNumber = this.ipToNumber(toIp);
              if (fromIpNumber > toIpNumber) {
                return false;
              }
              return true;
            },
            ipToNumber(ip) {
              return Number(
                ip.split('.')
                  .map(d => (`000${ d}`).substr(-3))
                  .join(''),
            );
          },

            createNewItem() {
              this.ipsRangeList.push({ from: '', to: '', error: '' });
              this.$emit('input', [...this.ipsRangeList]);
            },
            deleteItem(index) {
              this.ipsRangeList.splice(index, 1);
              this.$emit('input', [...this.ipsRangeList]);
            },
        },
    };
</script>

<style lang="scss" scoped>
  .ip-range-title {
    font-weight: 500;
  }
</style>
